import { graphql } from 'gatsby'
import React from 'react'
import GuideMarkdown from '../../components/GuideMarkdown'
import Seo from '../../components/Seo'
import { PAGE_VIEWED } from '../../constants/metrics'
import DocumentationLayout from '../../layouts/DocumentationLayout'
import MetricsService from '../../services/metricsService'

interface IGuideProps {
  data: {
    strapiGuides: {
      name: string
      content: any
    }
  }
}

export const query = graphql`
  query GuideQuery($name: String!) {
    strapiGuides(name: { eq: $name }) {
      name
      content
    }
  }
`

const Guides: React.FC<IGuideProps> = ({ data }): JSX.Element => {
  const { name, content } = data.strapiGuides
  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    pageName: name,
  })

  return (
    <DocumentationLayout>
      <Seo title={name} />
      <GuideMarkdown content={content} />
    </DocumentationLayout>
  )
}

export default Guides
